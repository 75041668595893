export default function ({ app, $axios }, inject) {
    const paypal = {
        /**
         * Obtiene el access token, necesario para hacer llamadas a api desde el frontend
         */
        async getAccessToken() {
            let value = await app.$api.paypal.accessToken()
                .then(res => {
                    return res;
                })
                .catch(error => {
                    console.log(error);
                });

            return value;
        },
        /**
         * Obtiene el Client ID
         */
        getClientId() {
            return process.env.PAYPAL_CLIENT_ID;
        },
    };

    inject('paypal', paypal);
}
