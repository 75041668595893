

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {},
  vueI18nLoader: false,
  locales: [{"name":"español","code":"es","file":"es.js","iso":"es-es"},{"name":"inglés","code":"en","file":"en.js","iso":"en-en"},{"name":"Alemán","code":"de","file":"de.js","iso":"de-de"},{"name":"Italiano","code":"it","file":"it.js","iso":"it-it"},{"name":"Portugués","code":"pt","file":"pt.js","iso":"pt-pt"}],
  defaultLocale: "es",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "/var/www/sites/antivirus.codes/locales",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  seo: true,
  baseUrl: "https://antivirus.codes",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onLanguageSwitched: () => null,
  normalizedLocales: [{"name":"español","code":"es","file":"es.js","iso":"es-es"},{"name":"inglés","code":"en","file":"en.js","iso":"en-en"},{"name":"Alemán","code":"de","file":"de.js","iso":"de-de"},{"name":"Italiano","code":"it","file":"it.js","iso":"it-it"},{"name":"Portugués","code":"pt","file":"pt.js","iso":"pt-pt"}],
  localeCodes: ["es","en","de","it","pt"],
}

export const localeMessages = {
  'es.js': () => import('../../locales/es.js' /* webpackChunkName: "lang-es.js" */),
  'en.js': () => import('../../locales/en.js' /* webpackChunkName: "lang-en.js" */),
  'de.js': () => import('../../locales/de.js' /* webpackChunkName: "lang-de.js" */),
  'it.js': () => import('../../locales/it.js' /* webpackChunkName: "lang-it.js" */),
  'pt.js': () => import('../../locales/pt.js' /* webpackChunkName: "lang-pt.js" */),
}
