import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _14926747 = () => interopDefault(import('../pages/avisolegal/index.vue' /* webpackChunkName: "pages/avisolegal/index" */))
const _4d891b5e = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _58843492 = () => interopDefault(import('../pages/condiciones/index.vue' /* webpackChunkName: "pages/condiciones/index" */))
const _63416f66 = () => interopDefault(import('../pages/contacto/index.vue' /* webpackChunkName: "pages/contacto/index" */))
const _6812aa32 = () => interopDefault(import('../pages/cookies/index.vue' /* webpackChunkName: "pages/cookies/index" */))
const _7792d132 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _107b208e = () => interopDefault(import('../pages/help-center/index.vue' /* webpackChunkName: "pages/help-center/index" */))
const _6ee036e8 = () => interopDefault(import('../pages/payment-pending/index.vue' /* webpackChunkName: "pages/payment-pending/index" */))
const _0750e64a = () => interopDefault(import('../pages/perfil/index.vue' /* webpackChunkName: "pages/perfil/index" */))
const _d65eadb4 = () => interopDefault(import('../pages/complaints/index.vue' /* webpackChunkName: "pages/complaints/index" */))
const _2e1b32a8 = () => interopDefault(import('../pages/recuperar-contrasena/index.vue' /* webpackChunkName: "pages/recuperar-contrasena/index" */))
const _794667de = () => interopDefault(import('../pages/sobre-nosotros/index.vue' /* webpackChunkName: "pages/sobre-nosotros/index" */))
const _c397f1ae = () => interopDefault(import('../pages/wizard/index.vue' /* webpackChunkName: "pages/wizard/index" */))
const _1c7e78e2 = () => interopDefault(import('../pages/amp/_brand_slug/index.vue' /* webpackChunkName: "pages/amp/_brand_slug/index" */))
const _37eddd73 = () => interopDefault(import('../pages/reviews/index.vue' /* webpackChunkName: "pages/reviews/index" */))
const _402185fe = () => interopDefault(import('../pages/cart/_swap/index.vue' /* webpackChunkName: "pages/cart/_swap/index" */))
const _8375ce4c = () => interopDefault(import('../pages/amp/_brand_slug/_product_slug/index.vue' /* webpackChunkName: "pages/amp/_brand_slug/_product_slug/index" */))
const _7a01b3f7 = () => interopDefault(import('../pages/help-center/_brand_slug/_product_slug/index.vue' /* webpackChunkName: "pages/help-center/_brand_slug/_product_slug/index" */))
const _7f3d5534 = () => interopDefault(import('../pages/help-center/_brand_slug/_product_slug/manual/index.vue' /* webpackChunkName: "pages/help-center/_brand_slug/_product_slug/manual/index" */))
const _6900781a = () => interopDefault(import('../pages/_brand_slug/index.vue' /* webpackChunkName: "pages/_brand_slug/index" */))
const _2c1c7e25 = () => interopDefault(import('../pages/_brand_slug/_product_slug/index.vue' /* webpackChunkName: "pages/_brand_slug/_product_slug/index" */))
const _71582631 = () => interopDefault(import('../pages/_brand_slug/_product_slug/reviews/index.vue' /* webpackChunkName: "pages/_brand_slug/_product_slug/reviews/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/avisolegal",
    component: _14926747,
    name: "avisolegal___es"
  }, {
    path: "/carrito",
    component: _4d891b5e,
    name: "cart___es"
  }, {
    path: "/condiciones",
    component: _58843492,
    name: "condiciones___es"
  }, {
    path: "/contacto",
    component: _63416f66,
    name: "contacto___es"
  }, {
    path: "/cookies",
    component: _6812aa32,
    name: "cookies___es"
  }, {
    path: "/de",
    component: _7792d132,
    name: "index___de"
  }, {
    path: "/en",
    component: _7792d132,
    name: "index___en"
  }, {
    path: "/help-center",
    component: _107b208e,
    name: "help-center___es"
  }, {
    path: "/it",
    component: _7792d132,
    name: "index___it"
  }, {
    path: "/payment-pending",
    component: _6ee036e8,
    name: "payment-pending___es"
  }, {
    path: "/perfil",
    component: _0750e64a,
    name: "perfil___es"
  }, {
    path: "/pt",
    component: _7792d132,
    name: "index___pt"
  }, {
    path: "/reclamaciones-devoluciones",
    component: _d65eadb4,
    name: "complaints___es"
  }, {
    path: "/recuperar-contrasena",
    component: _2e1b32a8,
    name: "recuperar-contrasena___es"
  }, {
    path: "/sobre-nosotros",
    component: _794667de,
    name: "sobre-nosotros___es"
  }, {
    path: "/wizard",
    component: _c397f1ae,
    name: "wizard___es"
  }, {
    path: "/de/avisolegal",
    component: _14926747,
    name: "avisolegal___de"
  }, {
    path: "/de/beschwerden-streitschlichtung",
    component: _d65eadb4,
    name: "complaints___de"
  }, {
    path: "/de/condiciones",
    component: _58843492,
    name: "condiciones___de"
  }, {
    path: "/de/contacto",
    component: _63416f66,
    name: "contacto___de"
  }, {
    path: "/de/cookies",
    component: _6812aa32,
    name: "cookies___de"
  }, {
    path: "/de/help-center",
    component: _107b208e,
    name: "help-center___de"
  }, {
    path: "/de/payment-pending",
    component: _6ee036e8,
    name: "payment-pending___de"
  }, {
    path: "/de/perfil",
    component: _0750e64a,
    name: "perfil___de"
  }, {
    path: "/de/recuperar-contrasena",
    component: _2e1b32a8,
    name: "recuperar-contrasena___de"
  }, {
    path: "/de/sobre-nosotros",
    component: _794667de,
    name: "sobre-nosotros___de"
  }, {
    path: "/de/warenkorb",
    component: _4d891b5e,
    name: "cart___de"
  }, {
    path: "/de/wizard",
    component: _c397f1ae,
    name: "wizard___de"
  }, {
    path: "/en/avisolegal",
    component: _14926747,
    name: "avisolegal___en"
  }, {
    path: "/en/cart",
    component: _4d891b5e,
    name: "cart___en"
  }, {
    path: "/en/complaints-dispute-resolution",
    component: _d65eadb4,
    name: "complaints___en"
  }, {
    path: "/en/condiciones",
    component: _58843492,
    name: "condiciones___en"
  }, {
    path: "/en/contacto",
    component: _63416f66,
    name: "contacto___en"
  }, {
    path: "/en/cookies",
    component: _6812aa32,
    name: "cookies___en"
  }, {
    path: "/en/help-center",
    component: _107b208e,
    name: "help-center___en"
  }, {
    path: "/en/payment-pending",
    component: _6ee036e8,
    name: "payment-pending___en"
  }, {
    path: "/en/perfil",
    component: _0750e64a,
    name: "perfil___en"
  }, {
    path: "/en/recuperar-contrasena",
    component: _2e1b32a8,
    name: "recuperar-contrasena___en"
  }, {
    path: "/en/sobre-nosotros",
    component: _794667de,
    name: "sobre-nosotros___en"
  }, {
    path: "/en/wizard",
    component: _c397f1ae,
    name: "wizard___en"
  }, {
    path: "/it/avisolegal",
    component: _14926747,
    name: "avisolegal___it"
  }, {
    path: "/it/carrello",
    component: _4d891b5e,
    name: "cart___it"
  }, {
    path: "/it/condiciones",
    component: _58843492,
    name: "condiciones___it"
  }, {
    path: "/it/contacto",
    component: _63416f66,
    name: "contacto___it"
  }, {
    path: "/it/cookies",
    component: _6812aa32,
    name: "cookies___it"
  }, {
    path: "/it/help-center",
    component: _107b208e,
    name: "help-center___it"
  }, {
    path: "/it/payment-pending",
    component: _6ee036e8,
    name: "payment-pending___it"
  }, {
    path: "/it/perfil",
    component: _0750e64a,
    name: "perfil___it"
  }, {
    path: "/it/reclami-risoluzione-delle-controversie",
    component: _d65eadb4,
    name: "complaints___it"
  }, {
    path: "/it/recuperar-contrasena",
    component: _2e1b32a8,
    name: "recuperar-contrasena___it"
  }, {
    path: "/it/sobre-nosotros",
    component: _794667de,
    name: "sobre-nosotros___it"
  }, {
    path: "/it/wizard",
    component: _c397f1ae,
    name: "wizard___it"
  }, {
    path: "/pt/avisolegal",
    component: _14926747,
    name: "avisolegal___pt"
  }, {
    path: "/pt/carrinho",
    component: _4d891b5e,
    name: "cart___pt"
  }, {
    path: "/pt/condiciones",
    component: _58843492,
    name: "condiciones___pt"
  }, {
    path: "/pt/contacto",
    component: _63416f66,
    name: "contacto___pt"
  }, {
    path: "/pt/cookies",
    component: _6812aa32,
    name: "cookies___pt"
  }, {
    path: "/pt/help-center",
    component: _107b208e,
    name: "help-center___pt"
  }, {
    path: "/pt/pagamento-pendente",
    component: _6ee036e8,
    name: "payment-pending___pt"
  }, {
    path: "/pt/perfil",
    component: _0750e64a,
    name: "perfil___pt"
  }, {
    path: "/pt/reclamacoes-resolucao-de-litigios",
    component: _d65eadb4,
    name: "complaints___pt"
  }, {
    path: "/pt/recuperar-contrasena",
    component: _2e1b32a8,
    name: "recuperar-contrasena___pt"
  }, {
    path: "/pt/sobre-nosotros",
    component: _794667de,
    name: "sobre-nosotros___pt"
  }, {
    path: "/pt/wizard",
    component: _c397f1ae,
    name: "wizard___pt"
  }, {
    path: "/de/amp/:brand_slug",
    component: _1c7e78e2,
    name: "amp-brand_slug___de"
  }, {
    path: "/de/meinungen/:page?",
    component: _37eddd73,
    name: "reviews___de"
  }, {
    path: "/de/meinungen/:page?",
    component: _37eddd73,
    name: "customer-reviews___de"
  }, {
    path: "/de/warenkorb/:swap",
    component: _402185fe,
    name: "cart-swap___de"
  }, {
    path: "/en/amp/:brand_slug",
    component: _1c7e78e2,
    name: "amp-brand_slug___en"
  }, {
    path: "/en/cart/:swap",
    component: _402185fe,
    name: "cart-swap___en"
  }, {
    path: "/en/reviews/:page?",
    component: _37eddd73,
    name: "reviews___en"
  }, {
    path: "/en/reviews/:page?",
    component: _37eddd73,
    name: "customer-reviews___en"
  }, {
    path: "/it/amp/:brand_slug",
    component: _1c7e78e2,
    name: "amp-brand_slug___it"
  }, {
    path: "/it/carrello/:swap",
    component: _402185fe,
    name: "cart-swap___it"
  }, {
    path: "/it/opinioni/:page?",
    component: _37eddd73,
    name: "reviews___it"
  }, {
    path: "/it/opinioni/:page?",
    component: _37eddd73,
    name: "customer-reviews___it"
  }, {
    path: "/pt/amp/:brand_slug",
    component: _1c7e78e2,
    name: "amp-brand_slug___pt"
  }, {
    path: "/pt/carrinho/:swap",
    component: _402185fe,
    name: "cart-swap___pt"
  }, {
    path: "/pt/opinioes/:page?",
    component: _37eddd73,
    name: "reviews___pt"
  }, {
    path: "/pt/opinioes/:page?",
    component: _37eddd73,
    name: "customer-reviews___pt"
  }, {
    path: "/de/amp/:brand_slug?/:product_slug",
    component: _8375ce4c,
    name: "amp-brand_slug-product_slug___de"
  }, {
    path: "/de/help-center/:brand_slug?/:product_slug",
    component: _7a01b3f7,
    name: "help-center-brand_slug-product_slug___de"
  }, {
    path: "/en/amp/:brand_slug?/:product_slug",
    component: _8375ce4c,
    name: "amp-brand_slug-product_slug___en"
  }, {
    path: "/en/help-center/:brand_slug?/:product_slug",
    component: _7a01b3f7,
    name: "help-center-brand_slug-product_slug___en"
  }, {
    path: "/it/amp/:brand_slug?/:product_slug",
    component: _8375ce4c,
    name: "amp-brand_slug-product_slug___it"
  }, {
    path: "/it/help-center/:brand_slug?/:product_slug",
    component: _7a01b3f7,
    name: "help-center-brand_slug-product_slug___it"
  }, {
    path: "/pt/amp/:brand_slug?/:product_slug",
    component: _8375ce4c,
    name: "amp-brand_slug-product_slug___pt"
  }, {
    path: "/pt/help-center/:brand_slug?/:product_slug",
    component: _7a01b3f7,
    name: "help-center-brand_slug-product_slug___pt"
  }, {
    path: "/de/help-center/:brand_slug?/:product_slug/manual",
    component: _7f3d5534,
    name: "help-center-brand_slug-product_slug-manual___de"
  }, {
    path: "/en/help-center/:brand_slug?/:product_slug/manual",
    component: _7f3d5534,
    name: "help-center-brand_slug-product_slug-manual___en"
  }, {
    path: "/it/help-center/:brand_slug?/:product_slug/manual",
    component: _7f3d5534,
    name: "help-center-brand_slug-product_slug-manual___it"
  }, {
    path: "/pt/help-center/:brand_slug?/:product_slug/manual",
    component: _7f3d5534,
    name: "help-center-brand_slug-product_slug-manual___pt"
  }, {
    path: "/amp/:brand_slug",
    component: _1c7e78e2,
    name: "amp-brand_slug___es"
  }, {
    path: "/carrito/:swap",
    component: _402185fe,
    name: "cart-swap___es"
  }, {
    path: "/de/:brand_slug",
    component: _6900781a,
    name: "brand_slug___de"
  }, {
    path: "/en/:brand_slug",
    component: _6900781a,
    name: "brand_slug___en"
  }, {
    path: "/it/:brand_slug",
    component: _6900781a,
    name: "brand_slug___it"
  }, {
    path: "/opiniones/:page?",
    component: _37eddd73,
    name: "reviews___es"
  }, {
    path: "/opiniones/:page?",
    component: _37eddd73,
    name: "customer-reviews___es"
  }, {
    path: "/pt/:brand_slug",
    component: _6900781a,
    name: "brand_slug___pt"
  }, {
    path: "/amp/:brand_slug?/:product_slug",
    component: _8375ce4c,
    name: "amp-brand_slug-product_slug___es"
  }, {
    path: "/de/:brand_slug/:product_slug",
    component: _2c1c7e25,
    name: "brand_slug-product_slug___de"
  }, {
    path: "/en/:brand_slug/:product_slug",
    component: _2c1c7e25,
    name: "brand_slug-product_slug___en"
  }, {
    path: "/help-center/:brand_slug?/:product_slug",
    component: _7a01b3f7,
    name: "help-center-brand_slug-product_slug___es"
  }, {
    path: "/it/:brand_slug/:product_slug",
    component: _2c1c7e25,
    name: "brand_slug-product_slug___it"
  }, {
    path: "/pt/:brand_slug/:product_slug",
    component: _2c1c7e25,
    name: "brand_slug-product_slug___pt"
  }, {
    path: "/de/:brand_slug/:product_slug/reviews",
    component: _71582631,
    name: "brand_slug-product_slug-reviews___de"
  }, {
    path: "/en/:brand_slug/:product_slug/reviews",
    component: _71582631,
    name: "brand_slug-product_slug-reviews___en"
  }, {
    path: "/help-center/:brand_slug?/:product_slug/manual",
    component: _7f3d5534,
    name: "help-center-brand_slug-product_slug-manual___es"
  }, {
    path: "/it/:brand_slug/:product_slug/reviews",
    component: _71582631,
    name: "brand_slug-product_slug-reviews___it"
  }, {
    path: "/pt/:brand_slug/:product_slug/reviews",
    component: _71582631,
    name: "brand_slug-product_slug-reviews___pt"
  }, {
    path: "/de/:brand_slug/:product_slug/reviews/:page",
    component: _71582631,
    name: "extended-customer-reviews___de"
  }, {
    path: "/en/:brand_slug/:product_slug/reviews/:page",
    component: _71582631,
    name: "extended-customer-reviews___en"
  }, {
    path: "/it/:brand_slug/:product_slug/reviews/:page",
    component: _71582631,
    name: "extended-customer-reviews___it"
  }, {
    path: "/pt/:brand_slug/:product_slug/reviews/:page",
    component: _71582631,
    name: "extended-customer-reviews___pt"
  }, {
    path: "/",
    component: _7792d132,
    name: "index___es"
  }, {
    path: "/:brand_slug",
    component: _6900781a,
    name: "brand_slug___es"
  }, {
    path: "/:brand_slug/:product_slug",
    component: _2c1c7e25,
    name: "brand_slug-product_slug___es"
  }, {
    path: "/:brand_slug/:product_slug/reviews",
    component: _71582631,
    name: "brand_slug-product_slug-reviews___es"
  }, {
    path: "/:brand_slug/:product_slug/reviews/:page",
    component: _71582631,
    name: "extended-customer-reviews___es"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
