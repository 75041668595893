/**
 * El sitio permite que a travez de unos parametros por querystring, se personalize por un tiempo la web, por ejemplo
 * el cliente quiere poder enviar un link al carrito, pero que no aparezca el header superior ni la navegacion por marcas
 * para que no se ponga a navegar por el sitio, que quede encerrado en el carrito para que compre.
 *
 * Esta configuracion queda guardadas en las cookies por 24hs, si despues visitamos el sitio lo vemos personalizado como antes.
 *
 * ?hbrands=1   hide brands (Esconde navegacion por marcas)
 * ?hmenu=1     hide menu (Esconde el menu superior)
 * ?sfc=1       show final countdown (Muestra el countdown de "se acaba la oferta") //TODO: Hacer el countdown
 * ?hid=1       hide direct download (Esconde el div de descarga inmediata en ProductResume y ProductBox)
 *
 * @param context
 * @param inject
 */
export default function ({ redirect, error, route, app }, inject) {
    const cookieKey = 'customization.data';
    let storedCustomization = {};

    try {
        storedCustomization = JSON.parse(app.$cookies.get(cookieKey, {}));
    } catch (e) {
    }

    // En el sitio viejo hay un parametro ?had que oculta no se que banda. Están las lógicas pero no hay un html condicional a eso.
    const customization = {
        //Shows the brand logo instead of the raw text
        show_brand_logo: route.query.had !== undefined ? parseInt(route.query.had) : !!storedCustomization.show_brand_logo,

        hide_direct_download: route.query.hid !== undefined ? parseInt(route.query.hid) : !!storedCustomization.hide_direct_download,
        hide_brands: route.query.hbrands !== undefined ? parseInt(route.query.hbrands) : !!storedCustomization.hide_brands,
        hide_menu: route.query.hmenu !== undefined ? parseInt(route.query.hmenu) : !!storedCustomization.hide_menu,
        show_final_countdown: route.query.sfc !== undefined ? parseInt(route.query.sfc) : !!storedCustomization.show_final_countdown, //TODO: Esto lo aviso en la ultima reunion, existe un countdown de quedan X min para esta oferta
    };

    if (JSON.stringify(customization) !== JSON.stringify(storedCustomization)) {
        app.$cookies.set(cookieKey, customization, { maxAge: 60 * 60 * 24 }); //24Hs
    }

    // Inject customization as $customization
    inject('customization', customization);
}
