const persistCartCookieKey = 'cart';
const campaignCookieKey = 'campaign.lastUsedSlug';
export const state = () => ({
  items: [
    /*
    product: {
      ...,
      prices: {
        data: [...]
      }
    },
    count: 1,
    licences: 1,
    */
  ],
  total: 0,
  empty: true,
});

export const mutations = {
  setItems(state, items) {
    state.items = items;
    state.empty = state.items.length === 0;
    
    let stateTotal = 0;

    for (let i = 0; i < items.length; i++){
      stateTotal += items[i].priceObj.final_price * items[i].count;
    }

    state.total = stateTotal; 
    
    //state.total = items.reduce((accum, item) => {
    //  return accum + (item.priceObj.final_price * item.count);
    //}, 0)
  },
};

export const actions = {
  /**
   * En un carrito convencional se agrega un item, pero en este sitio esta limitado a un unico item. Si en un futuro quieren
   * poder agregar multiples items, hay que hacer un push en vez de setear el array - commit('addItem', {product, etc})
   * Si en algun momento necesitan eso habria que hacer un nuevo diseño visual del "carrito" por que no es un carrito real
   * habria que agregar en algun lado el listado de productos que tenes en el carrito y cambiar los logos enormes del producto / marca
   *
   * @param obj {commit, rootGetters, state}
   * @param payload {product, licences, count}
   */
  addToCart({commit, rootGetters, state}, {product, licences, count = 1}){
    let prices = rootGetters.productFinalPricesById[product.id];

    
    const priceObj = rootGetters.productFinalPricesById[product.id].find(price => price.licences === parseInt(licences));

    commit('setItems', [{product, priceObj, licences: priceObj.licences, count}]);

    /* Ver donde persistir el carrito */
    this.$cookies.set(persistCartCookieKey, {
      items: state.items.map((item) => ({
        product_id: product.id,
        licences: priceObj.licences,
        count,
      })),
    });
    /* Ver donde persistir el carrito */
  },
  updateTotal({state, commit, rootGetters}){
    
    commit('setItems', state.items.map(item => {
      const priceObj = rootGetters.productFinalPricesById[item.product.id].find(price => price.licences === parseInt(item.licences));
      return {
        ...item,
        priceObj,
      }
    }));
    
  },
  clear({commit}){
    commit('setItems', []);
  },
  load({dispatch, commit, rootState, rootGetters}){
    /* Ver donde recuperar el carrito */
    try{
      const cartCookie = this.$cookies.get(persistCartCookieKey, {});
      const storedCart = cartCookie ? JSON.parse(cartCookie) : null;
      /**
       * TODO: Ver que onda cuando el carrito tiene un pack que ya no está en stock
       * 
       */
      if(storedCart){
        commit('setItems', storedCart.items.map(item => ({
          product: rootState.entities.products.find(p => p.id === item.product_id),
          priceObj: rootGetters.productFinalPricesById[item.product_id].find(price => price.licences === parseInt(item.licences)),
          licences: item.licences,
          count: item.count,
        })));
      }
     
    }catch(e){
      //Fallamos silenciosamente intencionalmente
      console.log('No se puede cargar el carrito desde la persistencia', {e});
    }
    /* Ver donde recuperar el carrito */

    return Promise.all([
      dispatch('updateTotal')
    ]);
  },

  // Este dispatch no altera el carrito pero si la cookie, añadendole email y nombre de quien compre.
  // Actualmente se llama a esta función unicamente cuando paypal te aprueba una pasarela de pago.
  // Después si se aprueba la compra, recuperamos estos datos para el form de review.

  setBuyerNameAndEmail({commit}, {firstName, lastName, email, orderID, campaign}){
    try{
      let cookie = this.$cookies.get(persistCartCookieKey);
      cookie.firstName = firstName;
      cookie.lastName = lastName;
      cookie.email = email;
      cookie.order = orderID;
      cookie.campaign = campaign;
      this.$cookies.set(persistCartCookieKey, cookie);
    } catch (e){
      console.log(e);
    }
  }
};
