const languageCookie = 'i18n_redirected';

export const state = () => ({
  appliedCampaign: null,
  intendedCampaignSlug: null,
  menuOpen: false,
  showReviewForm: false,
  showFixedNav: false,
  showCookieDisclaimer: false,
  showCookieConfiguration: false,
  navTitle: ''
})

export const mutations = {
  setAppliedCampaign(state, {campaign, intendedSlug}){
    state.appliedCampaign = campaign;
    state.intendedCampaignSlug = intendedSlug;
  },
  setMenuOpen(state, {open}){
    state.menuOpen = !!open;
  },
  toggleMenuOpen(state){
    state.menuOpen = !state.menuOpen;
  },
  toggleReviewForm(state){
    state.showReviewForm = !state.showReviewForm;
  },
  setReviewForm(state, {open}){
    state.showReviewForm = !!open;
  },
  setFixedNav(state, {show}){
    state.showFixedNav = !!show
  },
  setNavTitle(state, {title}){
    state.navTitle = title;
  },
  setCookieDisclaimer(state, {show}){
    state.showCookieDisclaimer = !!show;
  },
  toggleCookieConfiguration(state){
    state.showCookieConfiguration = !state.showCookieConfiguration;
  }
}

export const actions = {
  applyCampaign({commit, dispatch}, campaignSlug){
    if(campaignSlug){
      return this.$api.campaigns.index({slug: campaignSlug})
        .then((resp) => {
          commit('setAppliedCampaign', {campaign: resp.data[0] || null, intendedSlug: campaignSlug})
          dispatch('cart/updateTotal');
        })
        .catch((err) => {
          console.log('Ocurrio un error al buscar la campaña. No vera los descuentos', {err});
          commit('setAppliedCampaign', {campaign: null, intendedSlug: null});
        })
    }else{
      commit('setAppliedCampaign', {campaign: null, intendedSlug: null});
    }

    dispatch('cart/updateTotal');
  },
  nuxtServerInit ({ commit, dispatch }, { req, error }) {
   

    return Promise.all([
      dispatch('entities/load'),
    ]).then(() => {
      return dispatch('cart/load');     

    }).catch(err => {
      console.log('error', err);  
      console.log('Error cargando nuxtServerInit', {...err}); 
      error('Error cargando nuxtServerInit', err);
    });
  },
  openMenu({commit}){
    commit('setMenuOpen', {open: true});
  },
  closeMenu({commit}){
    commit('setMenuOpen', {open: false});
  },
  toggleMenu({commit}){
    commit('toggleMenuOpen');
  },
  toggleReviewForm({commit}){
    commit('toggleReviewForm');
  },
  closeReviewForm({commit}){
    commit('setReviewForm', {open: false});
  },
  setNavTitle({commit}, title){
    commit('setNavTitle', {title});
  },
  toggleFixedNav({commit}, shouldShow){
    if(shouldShow !== undefined){
      commit('setFixedNav', {open: shouldShow});
    } else {
      commit('toggleFixedNav');
    }
  },
  setCookieDisclaimer({commit}, show){
    commit('setCookieDisclaimer', {show: show});
  },
  toggleCookieConfiguration({commit}){
    commit('toggleCookieConfiguration');
  }
};

/**
 * Función para el getter productFinalPricesById 
 * 
 * Esta función toma como parametro un producto y devuelve los precios de acuerdo a si hay campaña o no.
 * @param product 
 */

function getProductPricesData(product){


}

export const getters = {
  userClosestPlatform: (state) => {
    return state.entities.platforms.find(p => {
      return p.icon_key.toLowerCase() === 'windows'; //TODO: Find closest to current platform [windows, mac o movil ?]
      //TODO: AGregar una key en el backend para determinarla sin usar el name
    });
  },
  /**
   *
   * Este getter nos permite tener centralizado el calculo de descuentos y descuentos de campaña en los productos.
   *
   * devuelve un objeto indexado por id de producto, con un array de precios al igual que product.prices.data, pero con
   * informacion adicional referente a los descuentos aplicados de la campaña. Si no hay campaña devuelve los valores normales.
   *
   * Los precios que se muestran al usuario deben ser los de este getter, asi se toma en cuenta las campañas aplicadas si las hubiera.
   *
   * @param state
   * @returns {*}
   */
  productFinalPricesById: (state) => {

    
    //let products = state.entities.products;
  //
    //let productsLength = products.length;
    //let obj = {};
//
    //let productCampaignDiscount = 0;
    //let productAppliedDiscount;
    //let prices = {};
    //let finalPrice;
    //// Iteramos por cada producto
    //for (let i = 0; i < productsLength; i++){
    //  // Definimos los precios del producto y calculamos el largo del array;
    //  prices = products[i].prices.data;
    //  let pricesLength = prices.length;
//
    //  obj[products[i].id] = [];
    //  // Iteramos por cada precio del producto en cuestión
    //  for(let x = 0; x < pricesLength; x++){
    //    productAppliedDiscount = prices[x].default_discount; // asignamos a la variable el descuento default del precio iterado.
//
    //    // Si hay campaña aplicada, le aplicamos al precio el descuento de campaña, si es que existe.
    //    if(state.appliedCampaign){
    //      let appliedCampaignLength = state.appliedCampaign.discounts.length;
//
    //      // Iteramos por la campaña buscando productos y asignandoles su valor correspondiente.
    //      for (let y = 0; y < appliedCampaignLength; y++){
    //        
    //        if (state.appliedCampaign.discounts[y].type === 'product' && state.appliedCampaign.discounts[y].id === product.id){
    //            productCampaignDiscount = state.appliedCampaign.discounts[y].discount;
    //            productAppliedDiscount = productCampaignDiscount;  
    //          break;
    //        }
    //      }
    //    }
    //    
    //    finalPrice = Number((prices[x].base_price * (1 - productAppliedDiscount / (100))).toFixed(2));
//
    //    obj[products[i].id][x] = {
    //      ...prices[x],
    //      campaign_discount: productCampaignDiscount,
    //      final_discount: productAppliedDiscount,
    //      final_price: finalPrice,
    //      final_savings: Number((prices[x].base_price - finalPrice).toFixed(2)),
    //    }
    //  }
    //}
//
    //return obj;
  
    
    return state.entities.products.reduce((obj, product) => {

      obj[product.id] = product.prices.data.map(price => {

        let productCampaignDiscount = 0;
        let productAppliedDiscount = price.default_discount;

        if(state.appliedCampaign){
          /*
          const campaignDiscountObject = state.appliedCampaign.discounts.find(disc => disc.type === 'product' && disc.id === product.id)

          if(campaignDiscountObject){
            productCampaignDiscount = campaignDiscountObject.discount;
            //productAppliedDiscount = Math.max(productAppliedDiscount, productCampaignDiscount); //TODO: Ver si se usa la mejor promo, o si puede llegar a salirte mas caro
            productAppliedDiscount = productCampaignDiscount;
          }*/
          let appliedCampaignLength = state.appliedCampaign.discounts.length;

          for (let i = 0; i < appliedCampaignLength; i++){
            if (state.appliedCampaign.discounts[i].type === 'product' && state.appliedCampaign.discounts[i].id === product.id){
                productCampaignDiscount = state.appliedCampaign.discounts[i].discount;
                productAppliedDiscount = productCampaignDiscount;  
              break;
            }
          }
        }
        
        let finalPrice = Number((price.base_price * (1 - productAppliedDiscount / (100))).toFixed(2));

        return {
          ...price,
          campaign_discount: productCampaignDiscount,
          final_discount: productAppliedDiscount,
          final_price: finalPrice,
          final_savings: Number((price.base_price - finalPrice).toFixed(2)),
        };
      });
      
      return obj;
    }, {});
  },
}
