const middleware = {}

middleware['autoCart'] = require('../middleware/autoCart.js')
middleware['autoCart'] = middleware['autoCart'].default || middleware['autoCart']

middleware['campaign'] = require('../middleware/campaign.js')
middleware['campaign'] = middleware['campaign'].default || middleware['campaign']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['navTitleClearer'] = require('../middleware/navTitleClearer.js')
middleware['navTitleClearer'] = middleware['navTitleClearer'].default || middleware['navTitleClearer']

export default middleware
