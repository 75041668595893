const cookieName = 'campaign.lastUsedSlug';

const applyNewCampaign = (store, app, newCampaignSlug) => {
  app.$cookies.set(cookieName, newCampaignSlug, {maxAge: 60 * 60 * 48});
  return store.dispatch('applyCampaign', newCampaignSlug);
}

/**
 * Este middleware se encarga de interpretar, persistir, recuperar y aplicar la campaña (Descuentos generados por el admin)
 *
 * Se envia en el query string ?campaign=codigo  y eso se persiste en una cookie para que se aplique esa campaña en futuras
 * rutas, y aplicarla en el state de vuex. Si la campaña no existe o no es valida simplemente no vera el descuento
 *
 * SSR Aware
 *
 * @param store
 * @param route
 * @param app
 * @returns {*}
 */
export default function ({ store, route, app }) {
  /* Damián: 05/10 Fix para que el carrito tome los valores del ultimo campaign slug en recarga*/
  if (route.name === 'carrito' && route.query.campaign == undefined){

    return Promise.all([
      store.dispatch('applyCampaign', app.$cookies.get(cookieName))
    ]).then(() =>{
       store.dispatch('cart/updateTotal');
    })
  }

  /* Si viene una campaña en la query, aplicamos esa (Si fuera necesario) */
  if(route.query.campaign !== undefined){
    const newCampaignSlug = route.query.campaign;

    if(!store.state.appliedCampaign || store.state.appliedCampaign.slug !== newCampaignSlug){
      return applyNewCampaign(store, app, newCampaignSlug);
    }
  }else{
    /* Si no viene una campaña en la query, buscamos la ultima que vino (Si es que la hay) */
      // la última campaña la determino desde las cookies
      const storedLastUsedSlug = app.$cookies.get(cookieName);

      if(storedLastUsedSlug && store.state.failedAppliedCampaign !== storedLastUsedSlug){
        return applyNewCampaign(store, app, storedLastUsedSlug);
      }
  }

}
