//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: ['error'],
  layout: 'default',
  mounted() {
    if(this.error.statusCode === 404){
      this.$tracking.error404();
    }
  }
};
