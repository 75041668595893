export default function ({ $axios }, inject) {
    if (process.env.NODE_ENV !== 'development') {
        return;
    }

    $axios.interceptors.request.use((config) => {
        config.params = config.params || {};

        config.params['XDEBUG_SESSION_START'] = 'PHPSTORM';

        return config;
    });
}
