/**
 * Plugin para el popup de visitantes online. Devuelve un random entre 2 numeros
 * específicados en las variables en el .env
 */

export default function({}, inject){
    let visitors = () => {
        let min = process.env.VISITORS_ONLINE_MIN * 1;
        let max = process.env.VISITORS_ONLINE_MAX * 1;
        let result = Math.round(Math.random() * (max - min + 1) + min);
        if(result % 2 === 0){
            return result + 1;
        } else {
            return result;
        }
    }
    inject('visitors', visitors);
}


