/**
 * Este middleware se encarga de interpretar los parametros de querystring que permiten agregar al carrito desde la url
 *
 * parametros
 * ?p= id de producto
 * ?q= cantidad de licencias
 *
 * No tiene particularidades de SSR
 *
 * @param store
 * @param route
 * @param app
 * @returns {*}
 */
export default function ({ store, route, app }) {
    if (route.query.p !== undefined && route.query.q !== undefined) {
        const productID = getProductID(parseInt(route.query.p));

        const product = store.state.entities.products.find(pr => pr.id === productID);
        const productPrice = product.prices.data.find(pp => pp.licences === parseInt(route.query.q));
        //Chequeamos que exista el product price, para no mandarlo al carrito con una cantidad inexistente.

        if (product && productPrice) {
            return store.dispatch('cart/addToCart', { product, licences: productPrice.licences, count: 1 });
        }
    }
}

/**
 * Change ID of requested product for a fixed one
 * @param {number} productID To be redirected (or not)
 * @returns {number} New productID redirected to || Original ID if no redirect were necessary
 */
const getProductID = (productID) => {
    const fixedProducts = {
        1: 33,
        2: 34,
        3: 35,
    };

    return fixedProducts[productID] ?? productID;
};