export default function ({ $axios, redirect, error }, inject) {
  const apiAxios = $axios;
  const api = {
    brands: {
      index: (params) => apiAxios.$get('/brands', {params}),
    },
    products: {
      index: (params) => apiAxios.$get('/products', {params}),
      download: (params) => apiAxios.$get('/download', {params})
    },
    characteristics: {
      index: (params) => apiAxios.$get('/characteristics', {params}),
    },
    securityLevels: {
      index: (params) => apiAxios.$get('/security_levels', {params}),
    },
    ratingCriteria: {
      index: (params) => apiAxios.$get('/rating_criteria', {params}),
    },
    posts: {
      index: (params) => apiAxios.$get('/posts', {params}),
    },
    platforms: {
      index: (params) => apiAxios.$get('/platforms', {params}),
    },
    places: {
      index: (params) => apiAxios.$get('/places', {params}),
    },
    campaigns: {
      index: (params) => apiAxios.$get('/campaigns', {params}),
    },
    contactMessages: {
      store: (data) => apiAxios.$post('/contact_messages', data),
    },
    clientReviews: {
      index: (params) => apiAxios.$get('/client_reviews', {params}),
      store: (data) => apiAxios.$post('/client_reviews', data),
    },
    orders: {
      index: (params) => apiAxios.$get('/orders', {params}),
      store: (data) => apiAxios.$post('/orders', data),
    },
    receipts: {
      index: (params) => apiAxios.$get('/receipts', {params})
    },
    languages: {
      index: (params) => apiAxios.$get('/languages', {params})
    },
    paymentMethods: {
      index: (params) => apiAxios.$get('/payment_methods', {params})
    },
    webConfiguration: {
      index: (params) => apiAxios.$get('/web_configuration', {params}),
    },
    paypal: {
      accessToken: (params) => apiAxios.$get('/paypal/access-token', {params}),
      completePurchase: (params) => apiAxios.$get('/paypal/complete-purchase', {params}),
    },
    vatStatus: {
      check: (data) => apiAxios.$post('/check_vat_status', data)
    },

    /* Hacer auth mas restfull ? nah */
    auth: {
      password: {
        /*
        email: (data) => apiAxios.$post('/auth/password/email', data),
        reset: (data) => apiAxios.$post('/auth/password/reset', data),
        */
        new: (data) => apiAxios.$post('/auth/password/new', data),
        change: (data) => apiAxios.$post('/auth/password/change', data),
      },
      profile: {
        save: (data) => apiAxios.$post('/auth/profile', data),
      },
      company: {
        change: (data) => apiAxios.$post('/auth/company/change', data)
      }
    },
    /* Hacer auth mas restfull ? */
  };

  // Inject api as $api
  inject('api', api);
}
