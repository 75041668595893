const defaultLanguage = process.env.DEFAULT_LANGUAGE;
const langHeaderName = process.env.LANG_HEADER_NAME;
const langCookieName = process.env.LANG_COOKIE_NAME;
const langNoCacheHeader = process.env.LANG_NO_CACHE_HEADER_NAME;

export default function({$axios, store, app, route, cookies}, inject) {

    const axios = $axios;
    axios.onRequest(() => {
        // console.log('poniendo header');
        axios.setHeader(langHeaderName, language.getLocaleCode(langCookieName))
        axios.setHeader(langNoCacheHeader, langNoCacheHeader);
    })

    const language = {
        /**
         * Código de idioma por default
         */
        default: defaultLanguage,

        /**
         * Cambia el header al string con el código de idioma seleccionado
         * @param {String} languageCode
         */
        setLanguage(languageCode){
            app.i18n.setLocale(languageCode).then(() => {
                app.$cookies.set(langCookieName, languageCode);
                // console.log('seteando header', languageCode);
                axios.setHeader(langHeaderName, languageCode);//languageCode);
                this.reloadStore();
                // console.log('seteando locale en i18n: ', languageCode);
            });
        },

        /**
         * Recarga el Vuex Store sin llamar a nuxtServerInit
         */
        reloadStore(){
            store.dispatch('entities/load');
        },

        /**
         *
         * Devuelve un codigo de idioma
         *
         * @param {String} cookie Identificador de cookie. Sirve para ver si el usuario
         * eligio idioma anteriormente.
         */
        getLocaleCode(cookie){
            return app.i18n.locale;

            // let langCookieValue = app.$cookies.get(cookie);
            //
            // if(cookie && langCookieValue){ // Si hay cookie, devolvemos cookie.
            //     // console.log('getLocaleCode', "devolvemos cookie", langCookieValue);
            //     return langCookieValue;
            //
            // } else { // Si no, revisamos la url
            //     // console.log('getLocaleCode', "no habia cookie, buscando coincidencias de langs en path");
            //     let locales = app.i18n.locales;
            //     let codeArray = [];
            //     let coincidence = false; // Checker para ver si hubo coincidencia de lang
            //     let routePath = route.path.split('/')[1]; // la onda es que el codigo este en la primera posicion
            //
            //     // Rescatamos el codigo de idioma unicamente
            //     for(let i = 0; i < locales.length; i++){
            //         codeArray.push(locales[i].code);
            //     }
            //
            //     // Buscamos entre los codigos de idioma habilitados si es que hay coincidencia
            //     codeArray.forEach((code) => {
            //         if (routePath === code){
            //             // console.log('getLocaleCode', "coincidencia encontrada, devolviendo", routePath);
            //             coincidence = true;
            //         }
            //     });
            //
            //     if(coincidence){
            //         return routePath // Mas que obvio
            //     }
            // }
            // // console.log('getLocaleCode', "fallback, devolvemos idioma default");
            // return defaultLanguage; // Fallback, devolvemos un idioma default.
        }
    }
    inject('language', language)
}
