export default function({}, inject){
    let review_multiplier = {
        product(){
            return parseInt(process.env.PRODUCT_REVIEW_MULTIPLIER);
        },
        brand(){
            return parseInt(process.env.BRAND_REVIEW_MULTIPLIER);
        },
        website(){
            return parseInt(process.env.WEBSITE_REVIEW_MULTIPLIER);
        }
    }

    inject('review_multiplier', review_multiplier);
}