/**
 *
 * @param $tc
 * @param $gtm
 * @param store
 * @param inject
 */
export default function (state, inject) {
    const { i18n, $gtm, store } = state;
    const currencyCode = 'EUR';

    const getBrandById = brand_id => {
        let brands = store.state.entities.brands;
        let brandsLength = brands.length;

        for (let i = 0; i < brandsLength; i++) {
            if (brands[i].id === brand_id) {
                return brands[i];
            }
        }
        //return store.state.entities.brands.find(b => b.id === brand_id);
    };

    const getProductFinalPriceById = (product_id, licences) => {
        const prices = store.getters.productFinalPricesById[product_id];
        let price;

        if (licences) {
            price = prices.find(p => p.licences === licences);
        } else {
            price = prices.filter(p => {
                return Math.min((p.max_shown_stock || p.stock), p.stock) > 0;
            })[0];
        }

        return price;
    };

    const getProductFinalFixedPriceById = (product_id, licences = null) => {
        const price = getProductFinalPriceById(product_id, licences);

        return price ? price.final_price.toFixed(2) : null;
    };

    const tracking = {
        v4: {
            itemList(products, listName) {
                $gtm.push({
                    event: 'view_item_list',
                    ecommerce: {
                        items: products.map((product, idx) => {
                            const brand = getBrandById(product.brand_id);
                            const price = getProductFinalPriceById(product.id);

                            return ({
                                item_name: `${brand?.name ?? ''} ${product.name}`,
                                item_id: product.id,
                                price: price.final_price,
                                item_brand: 'Codes',
                                item_category: brand?.name,
                                item_list_name: listName,
                                item_list_id: `${listName}_${product.id}`,
                                index: idx,
                                quantity: Math.min((price.max_shown_stock || price.stock), price.stock),
                            });
                        }),
                    },
                });
            },
            selectItem(product, listName) {
                const brand = getBrandById(product.brand_id);
                const price = getProductFinalPriceById(product.id);

                $gtm.push({
                    event: 'select_item',
                    ecommerce: {
                        items: [{
                            item_name: `${brand?.name ?? ''} ${product.name}`,
                            item_id: product.id,
                            price: price.final_price,
                            item_brand: 'Codes',
                            item_category: brand?.name,
                            item_list_name: listName,
                            item_list_id: `${listName}_${product.id}`,
                            quantity: Math.min((price.max_shown_stock || price.stock), price.stock),
                            index: 0,
                            item_variant: i18n.tc('labels.components.product_box.licences.available', price.licences, { count: price.licences }),
                        }],
                    },
                });
            },
            viewItem(product) {
                const brand = getBrandById(product.brand_id);
                const price = getProductFinalPriceById(product.id);

                $gtm.push({
                    event: 'view_item',
                    ecommerce: {
                        items: [{
                            item_name: `${brand?.name ?? ''} ${product.name}`,
                            item_id: product.id,
                            price: price.final_price,
                            item_brand: 'Codes',
                            item_category: brand?.name,
                            quantity: Math.min((price.max_shown_stock || price.stock), price.stock),
                            item_variant: i18n.tc('labels.components.product_box.licences.available', price.licences, { count: price.licences }),
                        }],
                    },
                });
            },
            addToCart(product, licences, listName) {
                const brand = getBrandById(product.brand_id);
                const price = getProductFinalPriceById(product.id, licences);

                $gtm.push({
                    event: 'add_to_cart',
                    ecommerce: {
                        items: [{
                            item_name: `${brand?.name ?? ''} ${product.name}`,
                            item_id: product.id,
                            price: price.final_price,
                            item_brand: 'Codes',
                            item_category: brand?.name,
                            item_variant: i18n.tc('labels.components.product_box.licences.available', price.licences, { count: price.licences }),
                            ...(listName ? {
                                item_list_name: listName,
                                item_list_id: `${listName}_${product.id}`,
                                index: 0,
                            } : {}),
                        }],
                    },
                });
            },
            beginCheckout(cartData) {
                $gtm.push({
                    event: 'begin_checkout',
                    ecommerce: {
                        items: cartData.items.map((item, idx) => {
                            const brand = getBrandById(item.product.brand_id);
                            const price = getProductFinalPriceById(item.product.id, item.licences);

                            return ({
                                item_name: `${brand?.name ?? ''} ${item.product.name}`,
                                item_id: item.product.id,
                                price: price.final_price,
                                item_brand: 'Codes',
                                item_category: brand?.name,
                                item_variant: i18n.tc('labels.components.product_box.licences.available', price.licences, { count: price.licences }),
                                quantity: item.count,
                                index: idx,
                            });
                        }),
                    },
                });
            },
            purchase(cartData, purchaseData, customTax = null) {
                const tax = Number(customTax ?
                        (cartData.total - (1 + (customTax / 100))).toFixed(2) :
                        (cartData.items[0].priceObj.final_price - (1 + (21 / 100))).toFixed(2));

                $gtm.push({
                    event: 'purchase',
                    ecommerce: {
                        transaction_id: purchaseData.order,
                        value: cartData.total,
                        tax,
                        currency: "EUR",
                        coupon: purchaseData.campaign,
                        items: cartData.items.map(item => {
                            const brand = getBrandById(item.product.brand_id);

                            return ({
                                item_name: `${brand?.name ?? ''} ${item.product.name}`,
                                item_id: item.product.id,
                                price: item.priceObj.final_price,
                                item_brand: 'Codes',
                                item_category: brand?.name,
                                item_variant: i18n.tc('labels.components.product_box.licences.available', item.priceObj.licences, { count: item.priceObj.licences }),
                                quantity: item.count,
                            });
                        }),
                    },
                });
            },
        },
        addToCart(product, licences) {
            $gtm.push({
                event: 'addToCart',
                ecommerce: {
                    currencyCode,
                    add: {
                        products: [{
                            name: `${product.name} ${product.subname}`,
                            id: product.id.toString(),
                            price: getProductFinalFixedPriceById(product.id, licences),
                            brand: getBrandById(product.brand_id).name,
                            quantity: 1,
                        }],
                    },
                },
            });
        },
        impressions(products, list) {
            $gtm.push({
                event: 'impressions',
                ecommerce: {
                    currencyCode,
                    impressions: products.map((product, i) => {
                        if (product && product.id) return {
                            name: `${product.name} ${product.subname}`,
                            id: product.id.toString(),
                            price: getProductFinalFixedPriceById(product.id),
                            brand: getBrandById(product.brand_id).name,
                            list,
                            position: i + 1,
                        };
                    }),
                },
            });
        },
        productClick(product, list) {
            $gtm.push({
                event: 'productClick',
                ecommerce: {
                    click: {
                        actionField: {
                            list,
                        },
                        products: [{
                            name: `${product.name} ${product.subname}`,
                            id: product.id.toString(),
                            price: getProductFinalFixedPriceById(product.id),
                            brand: getBrandById(product.brand_id).name,
                            position: 1,
                        }],
                    },
                },
            });
        },
        productPage(product) {
            $gtm.push({
                event: 'product_view',
                ecommerce: {
                    detail: {
                        actionField: {},
                        products: [{
                            name: `${product.name} ${product.subname}`,
                            id: product.id.toString(),
                            price: getProductFinalFixedPriceById(product.id),
                            brand: getBrandById(product.brand_id).name,
                        }],
                    },
                },
            });
        },
        checkout(cartData) {
            $gtm.push({
                event: 'checkout',
                ecommerce: {
                    checkout: {
                        actionField: {
                            step: '1',
                        },
                        products: cartData.items.map(item => ({
                            name: `${item.product.name} ${item.product.subname}`,
                            id: item.product.id,
                            price: item.priceObj.final_price.toFixed(2),
                            brand: getBrandById(item.product.brand_id).name,
                            quantity: item.count,
                        })),
                    },
                },
            });
        },
        /**
         * Use v4 instead
         * @param {object} cartData Los datos del carrito
         * @param {object} purchaseData Los datos de la compra
         * @param {object} customTax Si hay un vatStatus custom, usamos este, sino, aplicamos metodo default
         */
        purchase(cartData, purchaseData, customTax = null) {
            $gtm.push({
                event: 'purchase_old',
                ecommerce: {
                    purchase: {
                        actionField: {
                            id: purchaseData.order,
                            affiliation: 'Antivirus Codes',
                            revenue: store.state.cart.total,
                            tax: customTax ?
                                (store.state.cart.total - (1 + (customTax / 100))).toFixed(2) :
                                (cartData.items[0].priceObj.final_price - (1 + (21 / 100))).toFixed(2),
                            shipping: 0,
                            coupon: purchaseData.campaign,
                        },
                        products: cartData.items.map(item => ({
                            name: `${item.product.name} ${item.product.subname}`,
                            id: item.product.id,
                            price: item.priceObj.final_price.toFixed(2),
                            brand: getBrandById(item.product.brand_id).name,
                            quantity: item.count,
                        })),
                    },
                },
            });
        },
        error404() {
            $gtm.push({
                'event': '404',
            });
        },
    };

    // Inject tracking as $tracking
    inject('tracking', tracking);
}
