export const state = () => ({
  products: [],
  brands: [],
  characteristics: [],
  securityLevels: [],
  ratingCriteria: [],
  webConfiguration: {},
  websiteReviews: [],
  languages: [],
  paymentMethods: []
});

export const mutations = {
  setProducts(state, products) {
    state.products = products;
  },
  setBrands(state, brands) {
    state.brands = brands;
  },
  setCharacteristics(state, characteristics) {
    state.characteristics = characteristics;
  },
  setSecurityLevels(state, securityLevels) {
    state.securityLevels = securityLevels;
  },
  setWebConfiguration(state, webConfiguration) {
    state.webConfiguration = webConfiguration;
  },
  setRatingCriteria(state, ratingCriteria) {
    state.ratingCriteria = ratingCriteria;
  },
  setPlatforms(state, platforms) {
    state.platforms = platforms;
  },
  setWebsiteReviews(state, websiteReviews) {
    state.websiteReviews = websiteReviews;
  },
  /**
   * TODO: Ver si se traen las traducciones estáticas del backend o si las harcodeamos en un json en /lang
   */
  setLanguages(state, languages) {
    state.languages = languages;
  },
  setPaymentMethods(state, paymentMethods) {
    state.paymentMethods = paymentMethods;
  }
};

export const actions = {
  loadProducts({commit}){
    return this.$api.products.index({include: [
        'average_client_review_score',
        'client_reviews_count',
        'characteristic_values',
        'supported_platforms',
        'badges',
        'average_rating_score',
        'ratings',
        'help_center'
        //'stocks',
      ]})
      .then(resp => commit('setProducts', resp.data));
  },
  loadBrands({commit}){
    return this.$api.brands.index({include: [
        'ratings',
        'average_rating_score',
        'average_rating_score'
      ]})
      .then(resp => commit('setBrands', resp.data));
  },
  loadCharacteristics({commit}){
    return this.$api.characteristics.index()
      .then(resp => commit('setCharacteristics', resp.data));
  },
  loadSecurityLevels({commit}){
    return this.$api.securityLevels.index()
      .then(resp => commit('setSecurityLevels', resp.data));
  },
  loadRatingCriteria({commit}){
    return this.$api.ratingCriteria.index()
      .then(resp => commit('setRatingCriteria', resp.data));
  },
  loadPlatforms({commit}){
    return this.$api.platforms.index()
      .then(resp => commit('setPlatforms', resp.data));
  },
  loadWebsiteConfig({commit}){
    return this.$api.webConfiguration.index({
      include: ['faqs']
    })
      .then(resp => commit('setWebConfiguration', resp.data));
  },
  loadWebsiteReviews({commit}){
    return this.$api.clientReviews.index({ reviewable: "website"})
      .then(resp => commit('setWebsiteReviews', resp.meta));
  },
  loadLanguages({commit}){
    return this.$api.languages.index()
      .then(resp => commit('setLanguages', resp.data));
  },
  loadPaymentMethods({commit}){
    return this.$api.paymentMethods.index()
      .then(resp => commit('setPaymentMethods', resp.data));
  },
  load({dispatch}){
    return Promise.all([
      dispatch('loadBrands'),
      dispatch('loadPlatforms'),
      dispatch('loadProducts'),
      dispatch('loadCharacteristics'),
      dispatch('loadSecurityLevels'),
      dispatch('loadRatingCriteria'),
      dispatch('loadWebsiteConfig'),
      dispatch('loadWebsiteReviews'),
      dispatch('loadLanguages'),
      dispatch('loadPaymentMethods')
    ]);
  },
};
