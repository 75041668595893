export default function ({$gtm, store}, inject){
    const gtm = {
         init(){
             // if(store.state.entities.webConfiguration.pixel && process.env.GTM_ID){
             //     $gtm.init(process.env.GTM_ID);
             // } else {
             //     //
             // }
        }
    }
    inject('gtm', gtm);
}
