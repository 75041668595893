import Vue from 'vue'
import { wrapFunctional } from './utils'

const components = {
  AppFooter: () => import('../../components/AppFooter.vue' /* webpackChunkName: "components/app-footer" */).then(c => wrapFunctional(c.default || c)),
  AppHeader: () => import('../../components/AppHeader.vue' /* webpackChunkName: "components/app-header" */).then(c => wrapFunctional(c.default || c)),
  Backdrop: () => import('../../components/Backdrop.vue' /* webpackChunkName: "components/backdrop" */).then(c => wrapFunctional(c.default || c)),
  BlackFridayBanner: () => import('../../components/BlackFridayBanner.vue' /* webpackChunkName: "components/black-friday-banner" */).then(c => wrapFunctional(c.default || c)),
  BrandChipDescription: () => import('../../components/BrandChipDescription.vue' /* webpackChunkName: "components/brand-chip-description" */).then(c => wrapFunctional(c.default || c)),
  BrandLogoBanner: () => import('../../components/BrandLogoBanner.vue' /* webpackChunkName: "components/brand-logo-banner" */).then(c => wrapFunctional(c.default || c)),
  BrandRatingsBox: () => import('../../components/BrandRatingsBox.vue' /* webpackChunkName: "components/brand-ratings-box" */).then(c => wrapFunctional(c.default || c)),
  BrandRatingsComparer: () => import('../../components/BrandRatingsComparer.vue' /* webpackChunkName: "components/brand-ratings-comparer" */).then(c => wrapFunctional(c.default || c)),
  BuyNowButton: () => import('../../components/BuyNowButton.vue' /* webpackChunkName: "components/buy-now-button" */).then(c => wrapFunctional(c.default || c)),
  CartLicenceBlock: () => import('../../components/CartLicenceBlock.vue' /* webpackChunkName: "components/cart-licence-block" */).then(c => wrapFunctional(c.default || c)),
  CartPaymentFeedback: () => import('../../components/CartPaymentFeedback.vue' /* webpackChunkName: "components/cart-payment-feedback" */).then(c => wrapFunctional(c.default || c)),
  ChangePasswordForm: () => import('../../components/ChangePasswordForm.vue' /* webpackChunkName: "components/change-password-form" */).then(c => wrapFunctional(c.default || c)),
  CharityBlock: () => import('../../components/CharityBlock.vue' /* webpackChunkName: "components/charity-block" */).then(c => wrapFunctional(c.default || c)),
  ClientReviewsWidget: () => import('../../components/ClientReviewsWidget.vue' /* webpackChunkName: "components/client-reviews-widget" */).then(c => wrapFunctional(c.default || c)),
  CommonFaqs: () => import('../../components/CommonFaqs.vue' /* webpackChunkName: "components/common-faqs" */).then(c => wrapFunctional(c.default || c)),
  CompanyDataForm: () => import('../../components/CompanyDataForm.vue' /* webpackChunkName: "components/company-data-form" */).then(c => wrapFunctional(c.default || c)),
  ContactForm: () => import('../../components/ContactForm.vue' /* webpackChunkName: "components/contact-form" */).then(c => wrapFunctional(c.default || c)),
  CookieConfiguration: () => import('../../components/CookieConfiguration.vue' /* webpackChunkName: "components/cookie-configuration" */).then(c => wrapFunctional(c.default || c)),
  CookieDisclaimer: () => import('../../components/CookieDisclaimer.vue' /* webpackChunkName: "components/cookie-disclaimer" */).then(c => wrapFunctional(c.default || c)),
  DirectDownloadLabel: () => import('../../components/DirectDownloadLabel.vue' /* webpackChunkName: "components/direct-download-label" */).then(c => wrapFunctional(c.default || c)),
  DiscountLabel: () => import('../../components/DiscountLabel.vue' /* webpackChunkName: "components/discount-label" */).then(c => wrapFunctional(c.default || c)),
  Faqs: () => import('../../components/Faqs.vue' /* webpackChunkName: "components/faqs" */).then(c => wrapFunctional(c.default || c)),
  FaqsSeo: () => import('../../components/FaqsSeo.vue' /* webpackChunkName: "components/faqs-seo" */).then(c => wrapFunctional(c.default || c)),
  FixedBrandNav: () => import('../../components/FixedBrandNav.vue' /* webpackChunkName: "components/fixed-brand-nav" */).then(c => wrapFunctional(c.default || c)),
  FixedProductNav: () => import('../../components/FixedProductNav.vue' /* webpackChunkName: "components/fixed-product-nav" */).then(c => wrapFunctional(c.default || c)),
  HomeBrandBlocks: () => import('../../components/HomeBrandBlocks.vue' /* webpackChunkName: "components/home-brand-blocks" */).then(c => wrapFunctional(c.default || c)),
  HomeSlider: () => import('../../components/HomeSlider.vue' /* webpackChunkName: "components/home-slider" */).then(c => wrapFunctional(c.default || c)),
  HomeSliderSlide: () => import('../../components/HomeSliderSlide.vue' /* webpackChunkName: "components/home-slider-slide" */).then(c => wrapFunctional(c.default || c)),
  HomeSliderTest: () => import('../../components/HomeSliderTest.vue' /* webpackChunkName: "components/home-slider-test" */).then(c => wrapFunctional(c.default || c)),
  InnerContainer: () => import('../../components/InnerContainer.vue' /* webpackChunkName: "components/inner-container" */).then(c => wrapFunctional(c.default || c)),
  LanguageSelector: () => import('../../components/LanguageSelector.vue' /* webpackChunkName: "components/language-selector" */).then(c => wrapFunctional(c.default || c)),
  Loader: () => import('../../components/Loader.vue' /* webpackChunkName: "components/loader" */).then(c => wrapFunctional(c.default || c)),
  LoginForm: () => import('../../components/LoginForm.vue' /* webpackChunkName: "components/login-form" */).then(c => wrapFunctional(c.default || c)),
  OfferCountdown: () => import('../../components/OfferCountdown.vue' /* webpackChunkName: "components/offer-countdown" */).then(c => wrapFunctional(c.default || c)),
  OnsiteReviewForm: () => import('../../components/OnsiteReviewForm.vue' /* webpackChunkName: "components/onsite-review-form" */).then(c => wrapFunctional(c.default || c)),
  Paycomet: () => import('../../components/Paycomet.vue' /* webpackChunkName: "components/paycomet" */).then(c => wrapFunctional(c.default || c)),
  PendingReceiptPopup: () => import('../../components/PendingReceiptPopup.vue' /* webpackChunkName: "components/pending-receipt-popup" */).then(c => wrapFunctional(c.default || c)),
  PersonalDataForm: () => import('../../components/PersonalDataForm.vue' /* webpackChunkName: "components/personal-data-form" */).then(c => wrapFunctional(c.default || c)),
  PixelWrapper: () => import('../../components/PixelWrapper.vue' /* webpackChunkName: "components/pixel-wrapper" */).then(c => wrapFunctional(c.default || c)),
  PlatformCompatibilitySelector: () => import('../../components/PlatformCompatibilitySelector.vue' /* webpackChunkName: "components/platform-compatibility-selector" */).then(c => wrapFunctional(c.default || c)),
  PlatformIcon: () => import('../../components/PlatformIcon.vue' /* webpackChunkName: "components/platform-icon" */).then(c => wrapFunctional(c.default || c)),
  ProductBanner: () => import('../../components/ProductBanner.vue' /* webpackChunkName: "components/product-banner" */).then(c => wrapFunctional(c.default || c)),
  ProductBox: () => import('../../components/ProductBox.vue' /* webpackChunkName: "components/product-box" */).then(c => wrapFunctional(c.default || c)),
  ProductCharacteristicSecondaryItem: () => import('../../components/ProductCharacteristicSecondaryItem.vue' /* webpackChunkName: "components/product-characteristic-secondary-item" */).then(c => wrapFunctional(c.default || c)),
  ProductChipDescription: () => import('../../components/ProductChipDescription.vue' /* webpackChunkName: "components/product-chip-description" */).then(c => wrapFunctional(c.default || c)),
  ProductComparer: () => import('../../components/ProductComparer.vue' /* webpackChunkName: "components/product-comparer" */).then(c => wrapFunctional(c.default || c)),
  ProductComparerItemCharacteristics: () => import('../../components/ProductComparerItemCharacteristics.vue' /* webpackChunkName: "components/product-comparer-item-characteristics" */).then(c => wrapFunctional(c.default || c)),
  ProductFeatured: () => import('../../components/ProductFeatured.vue' /* webpackChunkName: "components/product-featured" */).then(c => wrapFunctional(c.default || c)),
  ProductFlyer: () => import('../../components/ProductFlyer.vue' /* webpackChunkName: "components/product-flyer" */).then(c => wrapFunctional(c.default || c)),
  ProductMainCharacteristics: () => import('../../components/ProductMainCharacteristics.vue' /* webpackChunkName: "components/product-main-characteristics" */).then(c => wrapFunctional(c.default || c)),
  ProductRatingsComparer: () => import('../../components/ProductRatingsComparer.vue' /* webpackChunkName: "components/product-ratings-comparer" */).then(c => wrapFunctional(c.default || c)),
  ProductResume: () => import('../../components/ProductResume.vue' /* webpackChunkName: "components/product-resume" */).then(c => wrapFunctional(c.default || c)),
  ProductReviewSection: () => import('../../components/ProductReviewSection.vue' /* webpackChunkName: "components/product-review-section" */).then(c => wrapFunctional(c.default || c)),
  ProductReviewSectionBlock: () => import('../../components/ProductReviewSectionBlock.vue' /* webpackChunkName: "components/product-review-section-block" */).then(c => wrapFunctional(c.default || c)),
  ProductReviewSectionComparation: () => import('../../components/ProductReviewSectionComparation.vue' /* webpackChunkName: "components/product-review-section-comparation" */).then(c => wrapFunctional(c.default || c)),
  ProductReviewSectionFeatured: () => import('../../components/ProductReviewSectionFeatured.vue' /* webpackChunkName: "components/product-review-section-featured" */).then(c => wrapFunctional(c.default || c)),
  ProductReviewSectionHeader: () => import('../../components/ProductReviewSectionHeader.vue' /* webpackChunkName: "components/product-review-section-header" */).then(c => wrapFunctional(c.default || c)),
  ProductReviewSectionRating: () => import('../../components/ProductReviewSectionRating.vue' /* webpackChunkName: "components/product-review-section-rating" */).then(c => wrapFunctional(c.default || c)),
  ProfileDashboard: () => import('../../components/ProfileDashboard.vue' /* webpackChunkName: "components/profile-dashboard" */).then(c => wrapFunctional(c.default || c)),
  RatingStars: () => import('../../components/RatingStars.vue' /* webpackChunkName: "components/rating-stars" */).then(c => wrapFunctional(c.default || c)),
  SecurityLevelLabel: () => import('../../components/SecurityLevelLabel.vue' /* webpackChunkName: "components/security-level-label" */).then(c => wrapFunctional(c.default || c)),
  SideMenu: () => import('../../components/SideMenu.vue' /* webpackChunkName: "components/side-menu" */).then(c => wrapFunctional(c.default || c)),
  Slider: () => import('../../components/Slider.vue' /* webpackChunkName: "components/slider" */).then(c => wrapFunctional(c.default || c)),
  Steps: () => import('../../components/Steps.vue' /* webpackChunkName: "components/steps" */).then(c => wrapFunctional(c.default || c)),
  TransitionExpand: () => import('../../components/TransitionExpand.vue' /* webpackChunkName: "components/transition-expand" */).then(c => wrapFunctional(c.default || c)),
  VisitorsOnlinePopup: () => import('../../components/VisitorsOnlinePopup.vue' /* webpackChunkName: "components/visitors-online-popup" */).then(c => wrapFunctional(c.default || c)),
  WizardProductResult: () => import('../../components/WizardProductResult.vue' /* webpackChunkName: "components/wizard-product-result" */).then(c => wrapFunctional(c.default || c)),
  AmpAppFooter: () => import('../../components/amp/AmpAppFooter.vue' /* webpackChunkName: "components/amp-app-footer" */).then(c => wrapFunctional(c.default || c)),
  AmpAppHeader: () => import('../../components/amp/AmpAppHeader.vue' /* webpackChunkName: "components/amp-app-header" */).then(c => wrapFunctional(c.default || c)),
  AmpBrandDescriptionSection: () => import('../../components/amp/AmpBrandDescriptionSection.vue' /* webpackChunkName: "components/amp-brand-description-section" */).then(c => wrapFunctional(c.default || c)),
  AmpBrandPicker: () => import('../../components/amp/AmpBrandPicker.vue' /* webpackChunkName: "components/amp-brand-picker" */).then(c => wrapFunctional(c.default || c)),
  AmpBrandReviewSection: () => import('../../components/amp/AmpBrandReviewSection.vue' /* webpackChunkName: "components/amp-brand-review-section" */).then(c => wrapFunctional(c.default || c)),
  AmpProductAwardsSection: () => import('../../components/amp/AmpProductAwardsSection.vue' /* webpackChunkName: "components/amp-product-awards-section" */).then(c => wrapFunctional(c.default || c)),
  AmpProductBox: () => import('../../components/amp/AmpProductBox.vue' /* webpackChunkName: "components/amp-product-box" */).then(c => wrapFunctional(c.default || c)),
  AmpProductCharacteristicMainItem: () => import('../../components/amp/AmpProductCharacteristicMainItem.vue' /* webpackChunkName: "components/amp-product-characteristic-main-item" */).then(c => wrapFunctional(c.default || c)),
  AmpProductCharacteristicSecondaryItem: () => import('../../components/amp/AmpProductCharacteristicSecondaryItem.vue' /* webpackChunkName: "components/amp-product-characteristic-secondary-item" */).then(c => wrapFunctional(c.default || c)),
  AmpProductCharacteristics: () => import('../../components/amp/AmpProductCharacteristics.vue' /* webpackChunkName: "components/amp-product-characteristics" */).then(c => wrapFunctional(c.default || c)),
  AmpProductResume: () => import('../../components/amp/AmpProductResume.vue' /* webpackChunkName: "components/amp-product-resume" */).then(c => wrapFunctional(c.default || c)),
  AmpProductReviewSection: () => import('../../components/amp/AmpProductReviewSection.vue' /* webpackChunkName: "components/amp-product-review-section" */).then(c => wrapFunctional(c.default || c)),
  AmpProductReviewSectionBlock: () => import('../../components/amp/AmpProductReviewSectionBlock.vue' /* webpackChunkName: "components/amp-product-review-section-block" */).then(c => wrapFunctional(c.default || c)),
  AmpProductReviewSectionComparation: () => import('../../components/amp/AmpProductReviewSectionComparation.vue' /* webpackChunkName: "components/amp-product-review-section-comparation" */).then(c => wrapFunctional(c.default || c)),
  AmpProductReviewSectionFeatured: () => import('../../components/amp/AmpProductReviewSectionFeatured.vue' /* webpackChunkName: "components/amp-product-review-section-featured" */).then(c => wrapFunctional(c.default || c)),
  AmpProductReviewSectionHeader: () => import('../../components/amp/AmpProductReviewSectionHeader.vue' /* webpackChunkName: "components/amp-product-review-section-header" */).then(c => wrapFunctional(c.default || c)),
  AmpProductReviewSectionRating: () => import('../../components/amp/AmpProductReviewSectionRating.vue' /* webpackChunkName: "components/amp-product-review-section-rating" */).then(c => wrapFunctional(c.default || c)),
  AmpRatingStars: () => import('../../components/amp/AmpRatingStars.vue' /* webpackChunkName: "components/amp-rating-stars" */).then(c => wrapFunctional(c.default || c)),
  AmpSidebar: () => import('../../components/amp/AmpSidebar.vue' /* webpackChunkName: "components/amp-sidebar" */).then(c => wrapFunctional(c.default || c)),
  CarritoCheckout: () => import('../../components/carrito/Checkout.vue' /* webpackChunkName: "components/carrito-checkout" */).then(c => wrapFunctional(c.default || c)),
  CarritoPaymentPending: () => import('../../components/carrito/PaymentPending.vue' /* webpackChunkName: "components/carrito-payment-pending" */).then(c => wrapFunctional(c.default || c)),
  CarritoPaypalCard: () => import('../../components/carrito/PaypalCard.vue' /* webpackChunkName: "components/carrito-paypal-card" */).then(c => wrapFunctional(c.default || c)),
  CarritoProductResume: () => import('../../components/carrito/ProductResume.vue' /* webpackChunkName: "components/carrito-product-resume" */).then(c => wrapFunctional(c.default || c)),
  BillingPaycomet: () => import('../../components/billing/Paycomet.vue' /* webpackChunkName: "components/billing-paycomet" */).then(c => wrapFunctional(c.default || c)),
  BillingPaycometAlternatives: () => import('../../components/billing/PaycometAlternatives.vue' /* webpackChunkName: "components/billing-paycomet-alternatives" */).then(c => wrapFunctional(c.default || c)),
  BillingPaycometIframe: () => import('../../components/billing/PaycometIframe.vue' /* webpackChunkName: "components/billing-paycomet-iframe" */).then(c => wrapFunctional(c.default || c)),
  CartCompanyDataForm: () => import('../../components/cart/CartCompanyDataForm.vue' /* webpackChunkName: "components/cart-company-data-form" */).then(c => wrapFunctional(c.default || c)),
  CartPersonalDataForm: () => import('../../components/cart/CartPersonalDataForm.vue' /* webpackChunkName: "components/cart-personal-data-form" */).then(c => wrapFunctional(c.default || c)),
  CartPurchaseError: () => import('../../components/cart/CartPurchaseError.vue' /* webpackChunkName: "components/cart-purchase-error" */).then(c => wrapFunctional(c.default || c)),
  CartPurchaseSuccess: () => import('../../components/cart/CartPurchaseSuccess.vue' /* webpackChunkName: "components/cart-purchase-success" */).then(c => wrapFunctional(c.default || c)),
  CartSingleProductResume: () => import('../../components/cart/CartSingleProductResume.vue' /* webpackChunkName: "components/cart-single-product-resume" */).then(c => wrapFunctional(c.default || c)),
  HelpCenterManualBrand: () => import('../../components/help_center/ManualBrand.vue' /* webpackChunkName: "components/help-center-manual-brand" */).then(c => wrapFunctional(c.default || c)),
  IconsCartIcon: () => import('../../components/icons/CartIcon.vue' /* webpackChunkName: "components/icons-cart-icon" */).then(c => wrapFunctional(c.default || c)),
  IconsDirectDownloadIcon: () => import('../../components/icons/DirectDownloadIcon.vue' /* webpackChunkName: "components/icons-direct-download-icon" */).then(c => wrapFunctional(c.default || c)),
  IconsFlagIcon: () => import('../../components/icons/FlagIcon.vue' /* webpackChunkName: "components/icons-flag-icon" */).then(c => wrapFunctional(c.default || c)),
  IconsLoginIcon: () => import('../../components/icons/LoginIcon.vue' /* webpackChunkName: "components/icons-login-icon" */).then(c => wrapFunctional(c.default || c)),
  IconsLogo: () => import('../../components/icons/Logo.vue' /* webpackChunkName: "components/icons-logo" */).then(c => wrapFunctional(c.default || c)),
  IconsMenuIcon: () => import('../../components/icons/MenuIcon.vue' /* webpackChunkName: "components/icons-menu-icon" */).then(c => wrapFunctional(c.default || c)),
  InputsInputButton: () => import('../../components/inputs/InputButton.vue' /* webpackChunkName: "components/inputs-input-button" */).then(c => wrapFunctional(c.default || c)),
  InputsInputError: () => import('../../components/inputs/InputError.vue' /* webpackChunkName: "components/inputs-input-error" */).then(c => wrapFunctional(c.default || c)),
  InputsInputText: () => import('../../components/inputs/InputText.vue' /* webpackChunkName: "components/inputs-input-text" */).then(c => wrapFunctional(c.default || c)),
  InputsInputTextarea: () => import('../../components/inputs/InputTextarea.vue' /* webpackChunkName: "components/inputs-input-textarea" */).then(c => wrapFunctional(c.default || c)),
  ReviewsCustomerReview: () => import('../../components/reviews/CustomerReview.vue' /* webpackChunkName: "components/reviews-customer-review" */).then(c => wrapFunctional(c.default || c)),
  ReviewsReviewFilters: () => import('../../components/reviews/ReviewFilters.vue' /* webpackChunkName: "components/reviews-review-filters" */).then(c => wrapFunctional(c.default || c)),
  SelectorsSelector: () => import('../../components/selectors/Selector.vue' /* webpackChunkName: "components/selectors-selector" */).then(c => wrapFunctional(c.default || c)),
  CarritoOldCheckout: () => import('../../components/carrito/old/Checkout.vue' /* webpackChunkName: "components/carrito-old-checkout" */).then(c => wrapFunctional(c.default || c)),
  CarritoOldProductResume: () => import('../../components/carrito/old/ProductResume.vue' /* webpackChunkName: "components/carrito-old-product-resume" */).then(c => wrapFunctional(c.default || c)),
  CartSwapNew: () => import('../../components/cart/swap/new.vue' /* webpackChunkName: "components/cart-swap-new" */).then(c => wrapFunctional(c.default || c)),
  CartSwapOld: () => import('../../components/cart/swap/old.vue' /* webpackChunkName: "components/cart-swap-old" */).then(c => wrapFunctional(c.default || c))
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
